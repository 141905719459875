.goals-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, 330px);
  background-color: $primary-white !important;
  justify-content: center;
}

.card-width {
  margin-right: 9px;
  width: 100% !important;
}

.plan-card-width {
  margin-right: 9px;
  width: 304px !important;
}

.text-bold-blue {
  font-weight: 500 !important;
  line-height: 21px !important;
  color: $primary-blue !important;
  font-size: 14px !important;
}

.row-border {
  border-bottom: 1px solid $dark-gray-600 !important;
}

.height-goal {
  min-height: 280px !important;
}

.image-background {
  background-image: url("../../../utils/images/goalImage.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.fund-image-backgound {
  background-image: url("../../../utils/images/usd.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 150px;
}

.blue-confirm-btn {
  border-radius: 3px;
  background-color: $primary-blue;
  color: $primary-white;
}

.grey-confirm-btn {
  border-radius: 3px;
  background-color: $gray-100;
  color: $primary-white;
}

.withdrw-modal-err {
  color: $primary-red;
  text-align: center;
}

.light-blue-cancel-btn {
  border-radius: 3px;
  background-color: $light-blue-400;
  color: $primary-blue;
}

.tr-color {
  color: $primary-blue;
}

.blue-separator {
  border-bottom: 0.496191px solid $light-blue-400;
}

.investment-summary {
  border: 1px solid $light-blue-545 !important;
}

.invest-in-new-fund-button {
  width: 196px
}

@media screen and (max-width: 400px) {
  .goals-container {
    grid-template-columns: repeat(auto-fit, 100%);
  }
}

@media screen and (max-width: 480px) {
  .card-width {
    width: 100% !important;
  }
}

@media screen and (max-width: 688px) {
  .card-width {
    width: 100% !important;
  }

  .invest-in-new-fund-button {
    width: 175px
  }
}

@media screen and (max-width: 1046px) {
  .resize {
    padding: 10px 10px !important;
    font-size: 12px !important;
  }
}

// new portfolio-page
.line-graph-section {
  // width: 726px;
  background: #deecf7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;

  font-style: normal;

  .portfolio-value {
    font-weight: 300;
    line-height: 51px;
    letter-spacing: -0.04em;
    color: #143f81;

    span {
      font-size: 34.2058px;
    }
  }

  .portfolio-title {
    font-weight: 500;
    font-size: 17.787px;
    line-height: 27px;
    letter-spacing: -0.04em;
    color: #3f4254;
  }

  .filter-section {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 15.5833px;
    line-height: 19px;
    text-align: right;
    letter-spacing: -0.04em;
    color: #143f81;
    align-items: center;
  }

  .filter-select {
    height: 30px;
    background: #deecf7;
    font-weight: 600;
    font-size: 15.5833px;
    border: 0.605523px solid #90caf9;
    border-radius: 9px;
    margin-left: 8.8px;
    padding-left: 18px;
    padding-right: 20px;
    margin-right: 27px;
    color: #143f81;
  }
}

// assets card
.portfolio-card-section {
  display: flex;
}

.assets-card {
  width: 356px;
  height: 117px;
  display: flex;
  flex-direction: row;
  background: #deecf7;
  border-radius: 8px;
}

.portfolio-card-section-mobile {
  gap: 10px !important;
}

.card-body-padding {
  padding: 2rem 5rem !important;
}

.btn-stats {
  width: 317px;
  font-weight: 600;
  font-size: 15.275px;
  line-height: 25px;
  /* or 163% */
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.view-less {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 317px;
  height: 47px;
  background: #0071ce;
  border-radius: 5.875px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15.275px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: auto;
  margin-top: 31px;
}

// portfolio-assets
.portfolio-asset-card {
  height: 104px;
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  flex: 0 0 32% !important;
  max-width: 32% !important;

  .portfolio-asset-image {
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }

  .portfolio-asset-image img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }

  .portfolio-card-asset-content {
    flex-grow: 1;
    white-space: nowrap;
  }
}

.blue-divider-custom {
  display: block;
  border-bottom: 1px solid $light-blue-600;
  width: 100%; 
}

.goal-details-container .blue-separator:last-child{
  display: none;
}

// mobile screen
@media (max-width: 480px) {
  // donutcharts
  .pie-chart-container-portfolio {
    display: flex;
    flex-direction: column !important;

    .pie-chart-info.grid-layout {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .portfolio-card-section {
    flex-direction: column;
  }

  .card-content-portfolio {
    justify-content: center;
  }

  .portfolio-asset-card {
    width: 100%;
    flex: 100% !important;
    max-width: 100% !important;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .pie-chart-container-portfolio {
    display: flex;
    flex-direction: column !important;

    .pie-chart-info.grid-layout {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .portfolio-card-section {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 !important;
  }

  .card-content-portfolio {
    justify-content: center;
  }

  .portfolio-asset-card {
    width: 100%;
    flex: 100% !important;
    max-width: 100% !important;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .portfolio-card-section {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 !important;
  }

  .card-content-portfolio {
    justify-content: center;
  }

  .portfolio-asset-card {
    flex: 0 0 49% !important;
    max-width: 49% !important;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .pie-chart-container-portfolio {
    display: flex;
    flex-direction: column !important;

    .pie-chart-info.grid-layout {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .portfolio-card-section-mobile {
    flex-direction: row;
  }

  .card-content-portfolio {
    justify-content: center;
  }
  .portfolio-card-section {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 !important;
  }

  .card-content-portfolio {
    justify-content: center;
  }

  .portfolio-asset-card {
    flex: 0 0 49% !important;
    max-width: 49% !important;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .portfolio-card-section {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 !important;
  }

  .card-content-portfolio {
    justify-content: center;
  }
}
