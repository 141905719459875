.bg-info-blue {
  height: auto;
  background: #0071ce;
  color: #ffffff;
}

.wizard-custom-selector {
  height: auto;
}

.wizard-custom-selector-team {
  height: auto;
  width: 49%;
}

.chosen {
  color: #fff !important;
}

.exit {
  font-size: 20px;
  margin-top: -33px;
  color: #0071ce;
  padding-bottom: -5px;
  cursor: pointer;
  float: right;
  font-weight: 500;
}

@media (max-width: 768px) {
  .wizard-custom-selector {
    height: auto !important;
  }
  .wizard-custom-selector-team {
    height: auto !important;
    width: 100%;
    padding: 10px;
  }
  .bg-info-blue {
    height: auto !important;
  }

  .exit {
    font-size: 18px;
  }
}

@media (max-width: 425px) {
  .exit {
    font-size: 14px;
    float: none;
    margin-top: 0;
  }
}
