.user-info {
  color: $primary-blue !important;
  font-weight: 500 !important;
  margin-bottom: 5px !important;
}

.first-name {
  color: $primary-blue !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.active-menu {
  background-color: $primary-blue !important;
  color: $primary-white !important;
}

.user-info {
  color: $primary-blue !important;
  font-weight: 500 !important;
  margin-bottom: 5px !important;
}

.signout-btn {
  background: $primary-orange !important;
  color: #fff !important;
  font-weight: bold !important;
}

.first-name {
  color: $primary-blue !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.active-menu {
  background-color: $primary-blue !important;
  color: $primary-white !important;
}

.active-right-menu-link {
  color: $primary-white !important;
}

.on-hover {
  background-color: $primary-blue !important;
  color: $primary-white !important;
  border-radius: 5px;
}

.active {
  color: $primary-white !important;
}

.link-nav {
  margin-bottom: 8px;
}

.right-nav {
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.active-right-menu-link {
  color: $primary-white !important;
}

.on-hover {
  background-color: $primary-blue !important;
  color: $primary-white !important;
}

.active {
  color: $primary-white !important;
}
