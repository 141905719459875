.plans-container {
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  background-color: $primary-white !important;
}

.saving-plans-container {
  display: flex;
  flex-wrap: wrap;
  background-color: $primary-white !important;
  justify-content: center;
}
.plans-card {
  flex: 0 0 auto !important;
  background-color: $primary-white !important;
  border: none !important;
}

.watchlist-height {
  max-height: 310px !important;
}

.goal-height {
  max-height: 400px !important;
}

.fund-height {
  max-height: 400px !important;
}

.plan-details-first {
  background-color: $gray-500;
  width: 50%;
  min-height: 150px;
  padding: 23px;
  padding-right: 10px !important;
  border-radius: 5px;
}

.plan-details-second {
  width: 50%;
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid $light-blue-545;
}

.height-plan {
  min-height: 216px !important;
  max-height: 330px;
}

.brand-label {
  padding: 4px 23px;
  background-color: $primary-white;
  border-radius: 4px;
  height: 29px;
}

.divider {
  display: block;
  border-bottom: 1px solid $light-blue-600;
}

.right-box {
  width: 80px;
  height: 60px;
  border: 1px solid $gray-500;
  border-radius: 5px;
}

.horizontal-line {
  border: 0.627123px solid $gray-500;
}

.fund-title {
  font-size: 20px;
}

.fund-description {
  width: 70%;
}

.center-container {
  margin-left: auto;
  margin-right: auto;
}

.company-brand-image {
  width: 80px;
  height: 48px;
  border: 0.74px solid #B0D3F0;
  padding: 8px;
  border-radius: 5px;
}

.small-brand-image {
  width: 50px;
  height: 30px;
  border: 1px solid $gray-500;
  border-radius: 2px;
}

.company-brand {
  background: $light-blue-100;
  border: 0.740386px solid $gray-500;
  border-radius: 5px;
}

.companies-container {
  overflow-x: auto;
  display: flex;
  gap: 20px;
  max-width: 568px;
  
}

.companies-container::-webkit-scrollbar {
  display: none;
}

.title {
  font-size: 15px;
  color: $primary-blue;
}

.sub-title {
  font-size: 14px;
}

.right-box-reponsive {
  width: 180px;
  height: 85px;
  background: $gray-500;
  border-radius: 5px;
}

.input-container {
  width: 400px !important;
}

.width {
  width: 50%;
}

.documents-width {
  width: 50%;
}

.set-goal-title {
  font-weight: 500;
  color: $dark-gray-600;
  font-size: 30px;
}

.resize-progress-bar {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -25px;
}

.input-field {
  padding: 0 15px !important;
  height: 45px !important;
}

.currency {
  background-color: $dark-gray-400 !important;
  border: 0.5px solid $light-blue-400 !important;
  border-radius: 0 5px 5px 0 !important;
  padding: 10px !important;
}

.pie-chart-container {
  width: 60% !important;
}

.risk-profile-container {
  width: 100% !important;
}

.investment-containers {
  width: 45% !important;
}

.amount-container {
  width: 30% !important;
}

.noUi-handle {
  background-color: $primary-orange !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.apexcharts-theme-light {
  margin: auto !important;
}

.risk-profile-container {
  padding: 20px !important;
}

.plan-details-container {
  min-height: 280px;
}

.right-container-mt {
  margin-top: 60px;
}

.icon-help {
  background: url("../../icons/helpIcon.svg") left top;
  width: 23px;
  height: 23px;
  display: block;
  float: left;
}

.font-size-25 {
  font-size: 25px;
}

.historical-margin {
  margin-top: 20%;
}

.goal-amount-container {
  background: $primary-white;
  padding: 20px 60px;
  border-radius: 5px;
}

.fixed-goal-amount-container {
  display: none;
}

.blue-divider {
  border-bottom: 0.437143px solid $light-blue-500;
  width: 100%;
  margin: auto;
}

.goal-amount-currency {
  background: $light-blue-400;
  color: $primary-blue;
  border: none;
  border-radius: 5px;
  outline: none;
  height: 45px;
  width: 130px;
  font-weight: 500;
  padding: 0 10px;
  cursor: pointer;
}

.user-preferred-currency {
  color: $primary-blue;
  border: 1px solid $primary-blue;
  border-radius: 4px;
  outline: none;
  height: 35px;
  font-weight: 500;
  padding: 0 10px;
  cursor: pointer;
}

.goal-amount {
  background: $primary-blue;
  color: $primary-white;
  width: 110px;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 5px;
}

.goal-amount-labels {
  font-size: 14px;
}

.goal-amount-slider-container {
  border-radius: 5px;
}

.input-card-container {
  min-width: 370px;
  width: 45%;
}

.slider-input-container {
  width: 94%;
  border-radius: 5px;
}

.goal-amount-sliders-container {
  font-size: 14px;
  font-weight: 500;
  background: $primary-white;
  width: 50%;
  border-radius: 5px;
}

.input-styles {
  border-radius: 8px;
  border: 1px solid $light-blue-545;
  outline: none;
  font-size: 13px;
}

.goal-investment-summary {
  width: 48%;
}

.container-width {
  width: 45%;
}

.portfolio-card {
  padding: 50px;
  background: $primary-white !important;
}

.blue-border {
  border: 1px solid $light-blue-400 !important;
}

.titles {
  font-size: 16px !important;
}

.risk-profile-types {
  font-size: 14px;
}

.saving-fund-width {
  width: 280px;
}

.plan-cool-name {
  color: $primary-blue;
}

.lock-in-period-tile {
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  background: $primary-orange;
  color: $primary-white;
  border-radius: 6px;
}

.fixed-return {
  color: $primary-blue;
  font-weight: 500;
}

.saving-plan-card-border {
  border: 2px solid $primary-blue !important;
}

.input-container {
  width: 351px !important;
 }

.responsive-width {
  width: 50%;
}

.brand-name {
  max-width: 105px;
}

@media screen and (max-width: 1368px) {
  .plans-card {
    margin-right: 9px;
  }

  .margin-left-50 {
    padding-left: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1065px) {
  .input-card-container {
    width: 100%;
  }
}

@media screen and (max-width: 1108px) {
  .plans-card {
    margin-right: 5px;
    margin-left: 5px;
  }

  .goal-amount-container {
    padding: 40px 10px;
  }
}

@media (min-width: 864px) and (max-width: 1141px)  {
  // .responsive-container {
  //   flex-direction: column;
  // }

  .width {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .set-goal-title {
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .plan-details-container {
    flex-direction: column !important;
  }

  .plan-details-first {
    width: 100%;
    height: 350px;
    border-radius: 5px 5px 0px 0px;
  }

  .plan-details-second {
    width: 100%;
    border-radius: 0 0 5px 5px;
  }

  .fund-description {
    width: 100%;
  }

  .performance-container {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .width {
    width: 100% !important;
  }

  .documents-width {
    width: 100%;
  }

  .inverstor-profile-container {
    flex-direction: column !important;
  }

  .risk-profile-container {
    width: 100% !important;
  }

  .pie-chart-container {
    width: 100% !important;
    margin-top: 40px !important;
  }

  .risk-profile-container {
    width: 100% !important;
  }

  .right-container-mt {
    margin-top: 10px;
  }

  .historical-margin {
    margin-top: 40px;
  }

  .goal-amount-container {
    padding: 40px 15px;
  }

  .fixed-goal-amount-container {
    width: 100%;
    display: block;
    background: $primary-white;
    padding: 20px 10px;
    border-radius: 5px;
    height: 100px;
  }

  .goal-amount-container {
    display: none;
  }

  .goal-amount-sliders-container {
    width: 100%;
    margin-top: 6px;
  }

  .set-goal-title {
    display: none;
  }

  .goal-amount {
    width: 110px;
    font-size: 14px;
  }

  .container-width {
    width: 100%;
  }

  .titles {
    font-size: 14px !important;
  }

  .goal-amount-labels {
    font-size: 14px;
  }

  .margin-left-50 {
    padding-left: 0;
  }

  .slider-input-container {
    width: 100%;
  }
}

@media screen and (max-width: 778px) {
  .input-card-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 650px) {
  .goal-investment-summary {
    width: 100%;
  }
  .donut-chart-container {
    margin-top: 15px;
  }
  .margin-left-50 {
    padding-left: 0;
  }
}

@media screen and (max-width: 635px) {
  .responsive-width {
    width: 70%;
  }
}

// @media screen and (max-width: 641px) {
//   .saving-plans-container {
//     justify-content: center;
//   }
// }
@media screen and (max-width: 576px) {
  .investment-containers {
    width: 100% !important;
    margin-top: 20px !important;
  }

  .portfolio-card {
    padding: 10px;
  }

  .margin-left-50 {
    padding-left: 0;
  }
}

@media screen and (max-width: 480px) {
  .plan-details-container {
    flex-direction: column !important;
  }

  .plan-details-first {
    width: 100%;
    height: 159px;
  }

  .plan-details-second {
    width: 100%;
  }

  .fund-description {
    width: 100%;
  }

  .companies-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .performance-container {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .right-box-reponsive {
    width: 180px;
    height: 65px;
  }

  .title {
    font-size: 12px;
    margin-top: -10px !important;
  }

  .sub-title {
    font-size: 8px;
  }

  .center-container {
    margin-left: 0;
    margin-right: 0;
  }

  .input-container {
    width: 100% !important;
  }

  .amount-container {
    width: 100% !important;
    margin-top: 20px !important;
  }

  .goal-calculator-container {
    flex-direction: column !important;
  }

  .goal-amount-sliders-container {
    width: 100%;
  }

  .goal-amount-container {
    display: none;
  }

  .pie-chart-container {
    margin-left: -3rem !important;
  }

  .margin-left-50 {
    padding-left: 0;
  }

  .input-card-container {
    min-width: 100% !important;
  }
}

@media screen and (max-width: 427px) {
  .brand-name {
    max-width: 80px;
  }
}

@media screen and (max-width: 415px) {
  .responsive-width {
    width: 100%;
  }
}

@media screen and (max-width: 347px) {
  .risk-profile-name {
    display: none !important;
  }

  .risk-profile-container {
    padding: 0 !important;
  }

  .set-goal-title {
    font-size: 18px;
  }

  .margin-left-50 {
    padding-left: 0;
  }
}

@media screen and (max-width: 320px) {
  .pie-chart-container {
    margin-left: -3rem !important;
  }

  .risk-profile-types {
    font-size: 12px;
  }

  .portfolio-card {
    padding: 5px;
  }

  .saving-plan-card-spacing {
    margin-left: 20px;
  }

  .margin-left-50 {
    padding-left: 0;
  }
}
