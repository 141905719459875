.close-icon{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #0071CE;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.close-icon-x{
    // padding-top: 10px;
    color: #ffffff;
    font-weight: 800;
}
@media (max-width: 320px) {
    .close-icon {  
        width: 20px;
        height: 20px;
     }
}
