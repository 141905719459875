.custom-radio-error .custom-radio {
    position: relative;
    padding-left: 14px;
  }

  /* Hide the default radio input */
  .custom-radio-error input {
    display: none;
  }

  .custom-radio input {
    display: none;
  }

  /* Create a custom radio indicator (the circle) */
  .custom-radio-error .radio-indicator {
    position: relative;
    left: 0;
    height: 24px;
    width: 24px;
    border: 2px solid $second-orange;
    border-radius: 4px;
    display: inline-block;
  }

  .custom-radio .radio-indicator {
    position: relative;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid $gray;
    border-radius: 4px;
    display: inline-block;
  }

  .custom-radio input[type="radio"]:checked + .check-box-label-container .radio-indicator {
    background-color: $primary-white !important;
    border-color: $primary-blue !important;
    padding: 1px;
  }

  .custom-radio input[type="radio"]:checked + .check-box-label-container .radio-indicator .radio-indicator-box {
    background-color: $primary-blue !important;
    height: 100%;
    width: 100%;
    display: inline-block;
    border-radius: 4px;
  }

  .title-spacing {
    margin-left: 20px
  }

  .sub-title-spacing {
    margin-left: 44px
  }