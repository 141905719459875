.payment-instructions-container {
    background-color: $white-300;
    border-radius: 8px;
    padding: 30px
}

.payment-instructions-container ol {
    margin-bottom: 0 !important;
}

.information-box {
    background-color: $orange-200;
    border-radius: 8px;
    padding: 13px;
    font-size: 13px;
    font-weight: 500;
}

.bonga-point {
    color: $primary-blue;
}

@media screen and (max-width: 415px) {
    .payment-instructions-container {
        padding: 30px 10px 30px 0px !important;
    }

    .payment-instructions-container ol{
        margin-left: -15px !important;
    }
}