.aside-menu-container {
  background-color: $primary-white !important;
  border-right: 1px solid $gray;
}

.dashboard-link {
  background-color: $primary-white !important;
  padding: 15px !important;
  border-bottom: 0.5px solid $gray;
}

.menu-text-color {
  color: $primary-blue !important;
  font-size: 13px !important;
  margin-left: 15px !important;
  font-weight: 500 !important;
}

.padding-bottom {
  padding: 15px !important;
  border-bottom: 0.5px solid $gray;
}

.active-menu-item {
  background-color: $primary-blue !important;
  color: $primary-white !important;
}

.hover-background:hover {
  background-color: $primary-blue !important;
}

.hover-color {
  color: $primary-white !important;
}
