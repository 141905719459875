.kyc-progress-bar {
  height: 45px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 45px;
  text-align: center;
  background: #fff;
  gap: 30px;
}

@media screen and (max-width: 1162px) {
  .kyc-progress-bar {
    width: 680px !important;
    height: 30px;
  }
}

@media screen and (max-width: 768px) {
  .kyc-progress-bar {
    width: 100% !important;
    height: 30px;
  }

  .kyc-progress-bar-text {
    display: none;
  }
}
