.input-field {
  padding: 24px !important;
  outline: none !important;
  height: 45px !important;
}

.input-field-amount {
  background-color: rgba(202, 226, 246, 0.25) !important;
  border: 0.5px solid #cae2f6 !important;
  border-radius: 5px 0px 0px 5px !important;
  padding: 24px !important;
  outline: none !important;
}

.input-width {
  width: 42% !important;
}

.select-input {
  background-color: rgba(202, 226, 246, 0.25) !important;
  border: 0.5px solid #cae2f6 !important;
  border-radius: 5px !important;
  padding: 0 15px !important;
  height: min-content !important;
}

.password-eye-icon {
  margin-top: -33px;
  margin-left: 85%;
  color: #c4c4c4;
  cursor: pointer;
}

.password-eye-icon-registration {
  margin-top: -33px;
  margin-left: 90%;
  color: #c4c4c4;
  cursor: pointer;
}

.padding-top-password {
  padding-top: 9px;
}

@media screen and (max-width: 990px) {
  .padding-top-password {
    padding-top: 16px;
  }
}

@media screen and (max-width: 425px) {
  .password-eye-icon-registration {
    margin-left: 82%;
  }
}

@media screen and (max-width: 320px) {
  .password-eye-icon-registration {
    margin-left: 78%;
  }
}
