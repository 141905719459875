.transaction-type {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.pocket-input-field {
    background-color: $second-white !important;
    border: 0.644px solid $light-blue-540 !important;
    outline: none !important;
    border-radius: 8px;
    font-size: 15px !important;
    color: $dark-gray-600 !important;
    width: 319px;
}

.pocket-icons-container {
    width: 55px;
    height: 55px;
}

.pocket-value {
    font-size: 40px;
    font-weight: 500;
}

.button-width {
    width: 250px;
}

.button-width-lg {
    width: 280px;
}

.twin-button-width {
    width: 48%;
}

.btn-medium {
    width: fit-content;
    margin: auto;
}

.buttons-spacing {
    margin-right: 10px;
}

.payment-method-container {
    width: 100%;
}

.confirm-trans-details-text {
    font-size: 13px;
}

.operation-page-header {
    width: 228px;
    line-height: 120%;
}

.pocket-operation-icon-container {
    width: 88px;
    height: 88px;
    padding: 20px;
    border-radius: 50%;
    background: $primary-white;
    box-shadow: 0px 2px 10px 0px $black-800;
}

@media screen and (max-width: 695px) {
    .buttons-spacing {
        margin-right: 0;
    }

    .twin-button-width {
        width: 100%;
    }
   
}

@media screen and (max-width: 635px) {
    .payment-method-container {
        width: 100%;
    }
    .display-sm-none {
        display: none;
    }
    .pocket-icons-container {
        width: 40px;
        height: 40px;
    }
    .pocket-balance-container {
        flex-direction: column;
    }
    .pocket-actions-container {
        margin-top: 25px;
    }
}

@media screen and (max-width: 415px) {
    .payment-method-container {
        width: 100%;
    }
    .pocket-value {
        font-size: 48px;
        
    }
    
}