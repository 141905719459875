.mobile-header-container {
  height: 61px !important;
  background: $primary-white !important;
}

.ndovu-logo-image {
  margin-top: -10px !important;
  width: 100%;
}

.ndovu-logo-container {
  width: 110px;
}

.partner-logo-image {
  width: 100%;
}

.partner-logo-container {
  width: 200px;
}

.mobile-profile-avatar {
 width: 100%;
 height: 100%;
}

.mobile-avatar-container {
  width: 40px;
  height: 40px;
}
