.big-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white !important;
  max-width: 776px;
}

.bank-transfer-note {
  color:#FF7F30;
  font-weight: 500;
  font-size: 17px;
  padding: 0px 5.5rem;
  margin-bottom: 1.5rem;
}

.bank-transfer-cards-wrapper {
  margin: auto;
}

.bank-transfer-instructions-wrapper { 
  width: 100%;
  padding: 20px;
}

.account-container {
  width: 100%;
  padding: 20px;
}

@media (max-width: 950px) {
  .pay-wth-bank-transfer-wrapper {
   flex-direction: column;
  }
}

@media (max-width: 768px) {
  .big-container {
    padding: 2rem 2px;
  }
  .bank-transfer-note {
    font-size: 15px;
    padding: 1px;
  }
 }

 @media screen and (max-width: 670px) {
  .bank-transfer-instructions-wrapper {
    padding: 10px;
  }

  .account-container {
    padding: 10px;
  }
}
