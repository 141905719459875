.payment-channel {
    font-size: 25px !important;
    font-weight: 400 !important;
}

.payment-channel-color {
    color: $primary-blue
}

.payment-channel-sub-title { 
    color: $primary-orange;
    font-weight: 500 !important;
    font-size: 17px;
}

.instructions-title {
    font-size: 17px !important;
    color: $primary-blue;
    font-weight: 600 !important;
}

@media screen and (max-width: 415px) {
    .payment-channel {
        font-size: 21px !important;
    }

    .payment-channel-sub-title { 
        font-size: 16px;
    }

    .instructions-title {
        font-size: 16px !important;
    }
}

