.content-container {
  // background: $white-400 !important;
  background-color: #ffffff !important;
  margin-top: -50px !important;
}

/* Place the navbar at the bottom of the page, and make it stick */
.bottom-menu {
  // background-color: #daeaf9;
  background-color: #ffffff;
  overflow: hidden;
  position: sticky !important;
  bottom: 0 !important;
  display: none;
  margin-bottom: -16px !important;
  margin-left: -40px !important;
  z-index: 200 !important;
}

.bottom-menu li{
  display: flex!important;
  justify-content: center !important;
  width: 100% !important;
}


.bottom-menu li a {
  display: inline-block !important;
  text-align: center;
  padding: 5px 16px;
  text-decoration: none;
  font-size: 17px;
  list-style: none !important;
}

.bottom-menu li:not(:last-child) {
  border-right: 1px solid rgba(0, 113, 206, 0.25) !important;
}

.bottom-menu li a span{
  display: block;
  align-items: center !important;
}

.bottom-menu li{
  list-style: none !important
}

.hover-bottom-item-background:hover {
  background-color: #0071ce !important;
  color: #fff !important;
}

.hover-bottom-item-color {
  color: #fff !important;
}

.active-bottom-menu-item {
  background-color: #0071ce !important;
  color: rgba(255, 255, 255, 1) !important;
}

.bottom-menu-text-color {
  color: #0071ce;
  font-size: 10px !important;
  margin-top: 2px;
}

.close-bar {
  display: flex;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.hide-close-bar-two {
  display: none;
}

.hide-close-bar-one {
  display: block;
}

.banner-content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .bottom-menu {
    display: flex;
  }

  .bottom-menu li a .svg-icon {
    margin-left: 0 !important;
  }
}

@media (max-width: 645px) {
  .hide-close-bar-two {
    display: block;
  }
  
  .hide-close-bar-one {
    display: none;
  }

  .banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .banner-content-container {
    display: flex;
    justify-content: unset;
    align-items: unset;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .bottom-menu li a {
    padding: 5px 0px 5px 0px;
  }
}

