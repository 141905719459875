.subs-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3px;
}
.subs-one-off-payment {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: center;
}

.subs-specific-info-wrapper {
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.subs-specific-info-text {
  font-size: 11px;
  letter-spacing: -0.04em;
  text-align: center
}

.fee-charge-info {
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: center;
}
 
 
.subs-btn-text {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.04em;
  text-align: center;
  background: #FF7F30;
  color: #ffffff;
}

.subs-list-info {
  padding-left: 20px;
  padding-right: 20px;
} 

.subs-subs-list-info li {
  font-family: Roboto;
   font-size: 12px;
   font-weight: 400;
}

.subs-free {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: center;
  color: #FF7F30;
}

.subs-fee {
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
}
