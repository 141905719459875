.date-of-birth-container {
  width: 94%;
}

.year-select {
  width: 30% !important;
}

.month-select {
  width: 40% !important;
}

.day-select {
  width: 26% !important;
}

.single-select {
  border: 0.5px solid  $dark-gray-450 !important;
  background: $primary-white !important;
  height: 45px;
  width: 100%;
  border-radius: 6px;
  outline: none;
  padding-left: 7px;
}

.active-option {
  font-weight: 500 !important;
}

.asterisk {
  display: inline;
  position: relative;
  top: 15px;
  color: #f05b7f;
  margin-left: 5px;
}

//Kyc reason modal
.reason-text-area {
  width: 100%;
  border: 0.6px solid #c4c4c4;
  font-size: 14px!important;
  padding: 13px;

}
textarea:focus, input:focus{
  outline: 0;
}

.blue-line {
  width: 100%;
  height: 0.3px;
  background: #D9EAF8;
}

.gray-line {
  width: 100%;
  height: 0.3px;
  background: #96969659;
}

.next-of-kin-reason-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.team-member-permissions-container {
  width: 100%;
}

.permission-container {
  flex: 0 0 50%;
  max-width: 50%;
}



@media screen and (max-width: 480px) {
  .date-of-birth-container {
    width: 100%;
    flex-direction: column !important;
  }

  .year-select {
    width: 99%;
    margin-top: 10px;
  }

  .month-select {
    width: 100%;
    margin-top: 10px;
  }

  .day-select {
    width: 100%;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 600px) {
  #launcher{
    bottom: 55px !important;
    right: -10px !important;
  }
}


@media screen and (min-width : 760px) and (max-width : 849px){
  .center-input {
    margin-left: auto;
    margin-right: auto;
  }

  .next-of-kin-reason-container {
    width: 303px;
  }

  .team-member-permissions-container { 
    width: 303px;
  }

  .permission-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 745px) {
  .center-input {
    margin-left: auto;
    margin-right: auto;
  }

  .next-of-kin-reason-container {
    width: 303px;
  }

  .team-member-permissions-container { 
    width: 303px;
  }

  .permission-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 990px) {
  .date-of-birth-container {
    width: 81.5%;
  }
}

// tablet view
@media screen and (min-width : 768px) and (max-width : 1023px){
  #launcher{
    bottom: 55px !important;
    right: -10px !important;
  }
}

@media screen and (min-width : 990px) and (max-width : 1134px){
  .center-input {
    margin-left: auto;
    margin-right: auto;
  }

  .next-of-kin-reason-container {
    width: 303px;
  }

  .team-member-permissions-container { 
    width: 303px;
  }

  .permission-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

