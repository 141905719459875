// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes (dark and light)
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.internal-container {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.external-container {
  background-color: #ffffff !important;
}

.hide-element {
  // display: none;
  // visibility: hidden;
  visibility: collapse !important;
  // height: 0px;
  // max-height: 0;
}

.show-element {
  visibility: visible !important;
  // max-height: none!important;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
  font-size: 13px !important;
}

input[type="email"],
input[type="month"],
input[type="password"],
input[type="text"],
input[type="number"] {
  font-size: 13px;
}

select {
  font-size: 13px !important;
}

.light-blue-background {
  background: #cae2f6 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 3px;
}

// #D0DFE6, #0071CE

/* Track */
::-webkit-scrollbar-track {
  background: #d0dfe6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0071ce;
  width: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (min-width: 320px) and (max-width: 480px) {
  .internal-container {
    .mobile-padding {
      padding: 0px 10px;
    }
  }

  .add-mobile-padding {
    padding: 0px 10px;
  }

  .input-mobile-padding {
    padding: 5px 0px;
  }
}
