.deactivate-modal {
  .icon {
    width: 79px;
    height: 72px;
    margin-left: 30px;
    margin-right: 20px;
  }

  .modal-right {
    align-items: left;

    .modal-summary-body {
      color: #3f4254;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.deactivate {
  .content-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 8px;
    padding: 16px;

    .content-left {
      .checkbox-custom,
      .checkbox-custom-label {
        content: "";
        background: #CCC;
        border-radius: 8px;
        display: inline-block;
        width: 24px !important;
        height: 24px !important;
        cursor: pointer;
      }

      .checkbox-custom:checked,
      .checkbox-custom-label:before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 5px;
        border: solid #0071ce;
        border-width: 0 3px 3px 0;
        border-radius: 8px;
      }
    }

    .content-right {
      display: flex;
      flex-direction: column;

      .title {
        color: #3f4254;
        font-size: 18px;
        font-weight: 600;
        line-height: 92%; /* 16.56px */
        letter-spacing: -0.54px;
        margin-top: 5px;
      }

      .deactivate-warning {
        padding-top: 7px;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .deactivate-modal {
    height: auto;
  }

  .deactivate {
    .content-card {
      .content-right {
        .title {
          margin-top: 5px !important;
        }
      }
    }
  }

  .modal-right {
    align-items: center;

    .modal-summary-body {
      font-size: 13px !important;
    }
  }
}

@media screen and (max-width: 1306px) {
  .deactivate-modal {
    width: 40% !important;
    height: auto;

    .icon {
      width: 59px;
      height: 65px;
      margin-left: 15px;
      margin-right: 10px;
    }

    .modal-right {
      align-items: left;

      .modal-summary-body {
        font-size: 13px !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .deactivate-modal {
    height: auto;
    .icon {
      height: 50px;
      width: 50px;
      margin-left: 15px;
      margin-right: 10px;
    }
    .modal-right {
      align-items: left;

      .modal-summary-body {
        font-size: 13px !important;
      }
    }
  }
}

@media screen and (max-width: 584px) {
  .deactivate-modal {
    height: auto;
    .modal-right {
      align-items: center;

      .modal-summary-body {
        font-size: 13px !important;
      }
    }
  }
}

@media screen and (max-width: 496px) {
  .deactivate {
    .content-card {
      .content-right {
        .title {
          margin-top: 5px !important;
        }
      }
    }
  }
  .deactivate-modal {
    width: 50% !important;

    height: auto;
    .modal-right {
      align-items: center;

      .modal-summary-body {
        font-size: 13px !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .deactivate-modal {
    width: 50% !important;
    height: auto;
    .modal-right {
      align-items: center;

      .modal-summary-body {
        font-size: 13px !important;
      }
    }
  }
}
