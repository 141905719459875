//
// Brand
//

.brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: get($header-config, desktop, default, height);

  // Logo
  .brand-logo {
    display: flex;
    justify-content: flex-begin;
    width: 100px;
    height: auto;
    margin-right: 10px;

    // Minimized Aside Mode
    .aside-minimize:not(.aside-minimize-hover) & {
      img {
        display: none;
      }
    }
  }

  // Toggle Icon
  .brand-toggle {
    .svg-icon {
      svg {
        transition: $transition;
      }
    }
  }
}

.partner-brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-right: 10px;
  height: 81px;
}

.partner-brand-logo {
  display: flex;
  justify-content: flex-begin;
  width: 80%;
  height: 71px;
  margin-right: 10px;

  // Minimized Aside Mode
  .aside-minimize:not(.aside-minimize-hover) & {
    img {
      display: none;
    }
  }
}

.loading-logo {
  width: 80%;
  height: 250px;
  margin: 10px 20px;

  // Minimized Aside Mode
  .aside-minimize:not(.aside-minimize-hover) & {
    img {
      display: none;
    }
  }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  .brand {
    // Fixed Header & Static Aside Mode
    .header-fixed.aside-static & {
      position: fixed;
      top: 0;
      left: 0;
      width: get($aside-config, base, width);
      z-index: get($header-config, desktop, fixed, zindex);
    }

    // Fixed Header & Aside Minimize Modes
    .aside-minimize:not(.aside-minimize-hover) & {
      justify-content: center;

      // Toggle Icon
      .brand-toggle {
        .svg-icon {
          svg {
            transition: $transition;
            transform: rotate(180deg);
            /*rtl:begin:*/
            //transform: rotate(-180deg);
            /*rtl:end:*/
          }
        }
      }
    }

    // Aside Minimize & Fixed Header & Static Aside Mode
    .header-fixed.aside-static.aside-minimize:not(.aside-minimize-hover) & {
      width: get($aside-config, base, width);
    }
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  .brand-toggle {
    display: none;
  }

  .brand {
    margin-top: 30px !important;
    margin-bottom: 0 !important;
    margin-left: 5px !important;
  }

  .svg-icon {
    margin-left: 20px !important;
  }

  .aside-menu-wrapper {
    margin-top: -15px !important;
  }
}
