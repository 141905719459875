
// Hot Funds
.fund-plans{
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scrollbar-color: #0071CE #D0DFE6;
  scrollbar-width: thin;
  gap: 18px;
}

.section-title{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.05em;
  color: #3F4254;
  font-family: Poppins;
}

.margin-top-25{
  margin-top: 15px;
}

.font-weight-normal{
  font-weight: normal !important;
  line-height: 30px;
}

.fund-card-more{
  height: 136.67px;
  width: 152.5px;
  flex: 1 0 auto;
  border-radius: 8px;
  background-color: #0071CE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  cursor: pointer;
}

.fund-card-img{
  width: 112.56px;
  height: 110.95px;
  border-radius: 5px;
}

.fund-card-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  color: #303030;
}

.fund-card-risk{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #3F4254;
}

// Learning
.learn{
  scrollbar-color: #0071CE #D0DFE6;
  scrollbar-width: thin;
}

.learn-card{
  max-width: 368px;
  height: 181px;
  border-radius: 8px;
  position: relative;
}

.top-right {
  position: absolute;
  top: 12px;
  right: 12px;
}

.centered-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.learn-card-time{
  width: 45px;
  background: #545454;
  border-radius: 21px;
  color: #fff;
  font-size: 11.8919px;
  text-align: center;
  padding-top: 2px;
  float: right !important;
  font-weight: 500;
}
  
  // .learn::-webkit-scrollbar {

  //   -webkit-appearance: none;
  //   width: 15px;
  //   background-color: lightgrey;
  
  // }
  
  // #learn::-webkit-scrollbar-thumb {
  
  //   background-color: var(--scrollbar-color);
  
  // }

.learn-card-title{
  margin-top: 10px;
  width: 335px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #3F4254;
  margin-bottom: 5px;
}

.learn-card-school{
  width: 358px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 119%;
  letter-spacing: -0.05em;
  color: #FF7F30;
}

.checkIT{
  cursor: pointer;
  width: 311.32px;
  min-height: 139.99px;
  border-radius: 7.83182px;
  margin-right: 11px;
  margin-bottom: 10px;
  flex: 1 0 auto;
}

.view-more-videos{
  width: 181px;
  height: 181px;
  border-radius: 8px;
  background-color: #0071CE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .section-title{
    font-size: 15px;
  }
  .checkIT{
    width: 330px;
    height: 165.03px;
    border-radius: 9.2327px;
    flex: 1 0 auto;
  }

  .learn-card{
    width: 302.11px;
    height: 148.59px;

      .learn-card-pill{
        width: 127.25px;
        height: 36.94px;

        .pill-icon{
          width: 32.02px;
          height: 32.02px;
          border-radius: 100%;
          margin-left: 3px;
        }
        span{
          font-size: 12.3143px;
        }
      }
      .learn-card-play{
        width: 62.89px;
        height: 62.89px;
      }
  }

  .fund-card{
    width: 305px;
    height: 136.67px;
  }

  .learn-card-title{
    width: 293.9px;
    font-size: 13.9562px;
  }
  
  .learn-card-school{
    width: 293.9px;
    font-size: 16.4191px;
  }
  .margin-top-25{
    margin-top: 10px;
  }
  .learning-modal {
    .modal-dialog{
      .modal-content{
        width: 700px;
        height: 300px;
      }
    }
    // width: 90vw;    /* Occupy the 90% of the screen width */
    // max-width: 90vw;
  }
  .triangle {
    position: relative;
    background-color: #FFFFFF;
    text-align: left;
    margin: 11px 0px 18px 22px;
  }
}

// modal
.learning-modal {
  .modal-dialog{
    .modal-content{
      width: 700px;
      max-width: 700px;
      height: 300px;
    }
  }
  // width: 90vw;    /* Occupy the 90% of the screen width */
  // max-width: 90vw;
}

.license-adjust{
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 148.49%;
/* or 19px */
display: flex;
text-align: center;
letter-spacing: -0.005em;
margin: 0px 50px 0px 50px;
color: #3F4254;
}

.ndovuFont{
  font-weight: 600;
}
