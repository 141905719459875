.cancel-btn-padding {
  padding: 1rem 2.25rem !important;
}

.invest-button-orange {
  background: $primary-orange !important;
  color: $primary-white !important;
  font-size: 16px
}

.kyc-back-button {
  background: $dark-blue !important;
  color: $primary-blue !important
}
