.business-overview {
  .auth-left {
    .email-section {
      color: #545454;
      font-size: 17px;
      font-weight: 500;
      line-height: 113%; /* 19.21px */
      letter-spacing: -0.51px;
    }
    .info-section {
      color: #ff7f30;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

// information

.custom-btn {
  display: flex;
  font-size: 13px;
  height: 35px;
  justify-content: center;
  align-items: center;
  gap: 8.651px;
  flex-shrink: 0;
  background-color: #0071ce;
  color: #ffffff;
  border-radius: 4px;
  font-weight: 500;
  line-height: 19.033px;
}

.custom-card-title {
  color: #143f81;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1px;
}

.btn-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkbox {
  align-items: flex-start !important;
  padding-right: 8px;
}

.custom-checkbox > input ~ span {
  background-color: #ffffff !important;
}

.custom-checkbox > input:checked ~ span {
  border-radius: 50%;
  background-color: #6ecb5f !important;
}

.custom-checkbox > input:checked ~ span:after {
  border-color: #ffffff !important;
}

#custom-checkbox input:checked ~ span::after {
  border-width: 0 3px 3px 0 !important;
}

.checkbox-label {
  color: #143f81;
  font-size: 13.368px;
  font-weight: 500;
  letter-spacing: -0.267px;
}

.custom-search {
  position: relative;
  // width: 300px;
  border: 0.5px solid #cae2f6;
  border-radius: 3.789px;
  background: #f5f9fd;
}
.custom-search-input {
  width: 75%;
  padding: 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: #f5f9fd;
}
.custom-search-botton {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  margin: 0;
  padding: 0 20px;
  border-radius: 5px;
  z-index: 2;
}

.scan-docs {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;

  .info-card {
    padding: 30px 0px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .card-icon {
      width: 96px;
      height: 70.502px;
      flex-shrink: 0;
      display: contents;
    }

    .card-text {
      width: 30%;
      flex-shrink: 0;
      color: #3f4254;
      text-align: center;
      font-size: 33.563px;
      font-weight: 400;
      line-height: 118.5%; /* 39.772px */
      letter-spacing: -1.678px;
    }

    .card-button {
      width: 70%;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}

.kyc-custom-phone-umber-container {
  width: 63%;
}

.custom-phoneNumber-container {
  width: 60%;
}

.custom-country-code-container {
  width: 40%;
}

.image-upload-cards-wrapper {
  gap: 16px;
}

.image-upload-card-wrapper {
  width: 346px;
  min-height: 209px;
}

.face-photo-upload-card-wrapper {
  width: 346px;
  min-height: 270px;
}

.buttons-gap {
  gap: 7px;
}

@media (min-width: 992px) and (max-width: 1158px) {
  .image-upload-card-wrapper {
    width: 100%;
  }

  .image-upload-cards-wrapper {
    flex-direction: column-reverse;
  }

  .face-photo-upload-card-wrapper {
    width: 100%;
  }
  
}

@media (max-width: 863px) {
  .image-upload-card-wrapper {
    width: 100%;
  }

  .image-upload-cards-wrapper {
    flex-direction: column-reverse;
  }

  .face-photo-upload-card-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .btn-section {
    display: flex;
    flex-direction: column;
  }

  .image-upload-cards-wrapper {
    margin-bottom: 25px;
  }
}

// handle styling for new personal account management screens
// customInput
.custom-input {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;

  .custom-input-width {
    max-width: 95%;
  }
}

.additional-hr {
  height: 0.1px;
  background-color: #0071ce;
}

// document upload section
.upload-section {
  display: flex;
  flex-direction: column;
  // align-items: center;
}

.personal-details {
  .date-of-birth {
    .extracted-year,
    .extracted-month,
    .extracted-day {
      width: 150px;
    }
  }
}

// verify deposit
.verify-deposit {
  display: flex;
  flex-direction: column;
  align-items: center;
  .deposit {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.note {
  border-radius: 7.111px;
  border: 0.267px solid #ff7f30;
  background: #ffe5d6;

  color: #ff5000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  line-height: 120.5%; /* 15.665px */
  letter-spacing: -0.39px;
  padding: 10px 20px;

  .note-label {
    font-weight: 700;
  }
  .note-content {
    font-weight: 500;
  }
}

.invite-card-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.input-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-selected-item { 
  height: 22px;
  overflow: hidden;
}

// mobile screen
@media (max-width: 480px) {
  .scan-docs {
    flex-direction: column;
    gap: 10px;
    .info-card {
      .card-icon > svg {
        width: 50px !important;
        height: 70px !important;
        flex-shrink: 0;
      }

      .card-text {
        width: 30%;
        font-size: 20px;
        width: 80%;
      }

      .card-button {
        width: 70%;
        font-size: 20px;
      }
    }
  }

  .management-card {
    padding: 10px !important;
    font-size: 15px !important;
  }

  .card-middle-content {
    padding: 0px !important;
    p {
      font-size: 18px;
    }
    .instruction-p {
      font-size: 15px;
    }
    ol {
      padding-left: 10px;
    }
  }

  .mobile-row {
    padding: 0px !important;

    .nextBtn {
      margin-right: 0px !important;
      max-width: 100% !important;
    }
  }

  .kyc-custom-phone-umber-container {
    width: 100%;
  }
}

@media (max-width: 482px) {
  .upload-section {
    width: 100%;
  }
  .invite-card-mobile {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .scan-docs {
    flex-direction: row;
    .info-card {
      width: 50%;

      .card-text {
        width: 30%;
        font-size: 15px;
        width: 80%;
      }

      .card-button {
        width: 70%;
        font-size: 15px;
      }
    }
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .scan-docs {
    flex-direction: row;
    .info-card {
      width: 50%;

      .card-text {
        width: 30%;
        font-size: 20px;
        width: 80%;
      }
    }
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .scan-docs {
    flex-direction: row;
    .info-card {
      width: 50%;

      .card-text {
        width: 30%;
        font-size: 20px;
        width: 80%;
      }
    }
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .scan-docs {
    flex-direction: row;
    .info-card {
      width: 50%;

      .card-text {
        width: 30%;
        font-size: 20px;
        width: 80%;
      }
    }
  }
}
