.kyc-reminder {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  background: #0071CE;
  border-radius: 4px;
  color: #fff;

  &:hover {
  background-color: #FFFFFF;
  border: 0.3px solid #0071CE;
  color: #0071CE;
  transition: all 0.5s ease-in-out;

  .kyc-icon {
    background: #ffffff;
    transition: all 0.5s ease-in-out;

    svg {
      fill: #0071CE !important;
      transition: all 0.5s ease-in-out;
    }
  }
 }
  .btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 111%;
    /* or 17px */
    color: #FFFFFF;
  }

  .kyc-icon {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0071CE;

    svg {
      fill: white !important;
    }
   
  }

  .kyc-reminder-text {
    font-weight: 500;
    font-size: 13px;
    display: flex;
    align-items: center;
  }
}

@media (width <= 768px) {
  .kyc-reminder {
    .kyc-reminder-text {
      font-size: 10px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .kyc-reminder {
    width: 100%;
    padding: 0px 10px;

    .kyc-icon{
      width: 16px;
      height: 16px;
    }
  }
}