$dark-blue: rgba(0, 113, 206, 0.25);
$dark-blue-800: #005092;
$dark-blue-700: #143f81;
$dark-blue-600: #143f81;
$dark-blue-300: #338DD8;
$dark-blue-200: #90caf9;

$primary-blue: #0071ce;
$light-blue: #9fd0f9;
$light-blue-650: #EBF4FB;
$light-blue-600: #8ABEE8;
$light-blue-500: #a7d7ff;
$light-blue-550: #90caf9;
$light-blue-545: #94c3ea;
$light-blue-540: #d0dfe6;
$light-blue-530: #ebf4fb;
$light-blue-500: #a7d7ff;
$light-blue-460: #B0D3F0;
$light-blue-455: #F5F9FD;

$light-blue-450: #ebf4fb;
$light-blue-400: #cae2f6;
$light-blue-350: #E6F1FA;
$light-blue-300: rgba(217, 234, 248, 1);
$light-blue-250: #eef2f7;
$light-blue-200: #d9eaf8;
$light-blue-150: rgba(208, 223, 230, 1);
$light-blue-100: #deecf7;
$light-blue-60: #f5f9fd;
$light-blue-50: #ebf3fa;
$light-blue-40: #fafcfe;

$blue-sky-400: #8abee8;

$primary-white: #ffffff;
$second-white: #f5f9fd;

$white-600: rgba(255, 255, 255, 1);
$white-400: #f2f8fd;
$white-300: #eef2f6;
$white-200: #efefef;

$dark-gray-700: #4A4A4A;
$dark-gray-600: #3f4254;
$dark-gray-550: #4a4a4a;
$dark-gray-500: #545454;
$dark-gray-450: #919191;
$dark-gray-400: rgba(202, 226, 246, 0.25);
$dark-gray-350: #6b788e;
$dark-gray-300: #858585;
$dark-gray-200: #636363;

$light-gray-600: #b5b5c3;

$gray: #cccccc;

$gray-700: #757575;
$gray-600: #919191;
$gray-550: #d6d2d2;
$gray-500: #c4c4c4;

$gray-450:#F7F7F7;
$gray-440:#C0C0C0;
$gray-400: #e6e6e6;

$gray-350: #9E9E9E;
$gray-300: #969696;

$gray-250: #758389;
$gray-200: #e8ebee;

$gray-100: #e4e1e1;
$gray-50: #e4e6ef;

$primary-red: #ec1a23;
$second-red: #F10A00;
$red-700: #a20012;
$red-600: #ff3636;
$red-500: #f05b7f;
$red-300: #FF7878;
$red-350: #FF7878;
$red-200: #ff5000;

$primary-orange: #ff7f30;
$second-orange: #f77361;
$orange-700: #ff9966;
$orange-650: #FF7F30;
$orange-600: #ff7f3061;
$orange-500: #ff7f3026;
$orange-400: #FF9959;
$orange-300: #ffe5d6;
$orange-250: #FFC4A0;
$orange-200: #ffe7e3;
$orange-150: #fff2ea;
$orange-100: #fff1ef;
$orange-90: #FFF2EA;

$primary-green: #2d9a2d;
$second-green: #14AA11;


$primary-black: #000;
$black-800: rgba(0, 0, 0, 0.10);
$black-700: #343434;
$black-600: #595959;

$yellow: #FFA500
