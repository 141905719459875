.main-wrapper {
  max-width: 800px;
  margin: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: white !important;
}

@media screen and (max-width: 670px) {
  .main-wrapper {
    padding: 16px;
  }
}