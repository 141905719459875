.add-button {
  width: 122px;
  height: 40px;
  text-align: center;
  font-size: 13px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countryCode,
.phoneNumber {
  border-radius: 3.787px;
  border: 1px solid #cae2f6;

  background: #f5f9fd;
}
.details span {
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: -0.6px;
}
.mobile-money,
.bank-details {
  background: #FAFCFE;

  .account-title {
    color: #3f4254;
    font-size: 13.333px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 15.867px */
    letter-spacing: -0.667px;
  }

  .bank-name {
    color: #3f4254;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.75px;
  }
}
.details {
  border-radius: 3.787px;
  border: 1px solid #cae2f6;
  background: #f5f9fd;
  margin-top: 5px;
}
.status-title {
  color: #F54E4F;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 119%; /* 15.867px */
  letter-spacing: -0.4px;
}
.status-text {
color: #3F4254;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 121%; /* 16.94px */
letter-spacing: -0.42px;
}

.approved-status {
  font-size: 14px;
}

.approved-status-text {
  font-size: 12px;
}

.title {
  font-size: 14px !important;
}


@media (width <= 440px) {
  .title {
    font-size: 13px !important;
  }

  .add-button {
    width: 60px;
    height: 30px;
    font-size: 12px;
  }
}

