.goalCard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

// pie chart
.pie-chart-section {
  display: flex;
  flex-direction: column;
  padding: 12px 19px;

  .pie-chart-content {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 20;

    ul {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      gap: 10px;
      font-size: 14.3156px;
      padding: 15px;
      height: 250px;
      overflow-y: scroll;

      li {
        list-style-type: none;

        .pie-chart-content-list {
          display: flex;
          flex-direction: column;
        }
      }
    }

    ul::-webkit-scrollbar {
      width: 3px;
    }

    ul::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 1.5px;
    }

    ul::-webkit-scrollbar-thumb {
      border-radius: 1.5px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
}

// goal cards
.goal-cards-container {
  display: flex;
  gap: 15px;
}
.single-goal-card {
  height: 155px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  padding: 8px;
  flex: 0 0 32% !important;
  max-width: 39% !important;

  .goal-card-row1 {
    display: flex;
    flex-direction: row;

    .goal-name {
      white-space: nowrap;
      overflow: hidden;
      font-weight: 500;
      font-size: 16px;
    }

    .goal-date {
      padding: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 110px;
      justify-content: center;
    }
  }

  .goal-card-row2 {
    width: 100%;
    height: 15px;
  }

  .goal-card-row3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .goal-target {
      .goal-target-title {
        display: flex;
        flex-direction: row;
        justify-content: end;
      }
    }
  }

  .side-left {
    margin-left: 18px;

    .goal-name {
      font-style: normal;
      font-weight: 600;
      font-size: 17.4103px;
      line-height: 26px;
      color: #0071ce;
    }

    .goal-detail {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13.8855px;
      line-height: 119%;
    }
  }
}

@media (max-width: 1127px) {
  .goal-cards-container {
    gap: 10px;
  }
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .pie-chart-section {
    display: flex;
    flex-direction: column;
    padding: 12px 19px;

    .pie-chart-content {
      display: flex;
      flex-direction: column;

      .chart-list {
        height: auto;
      }
    }
    .goal-currency {
      font-weight: 400 !important;
      font-size: 16px !important;
    }

    .pie-chart-content > div {
      margin-left: -30px;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .single-goal-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (max-width: 600px) {
  .middle-section {
    margin-left: -0px !important;
  }

  .goal-date > div {
    white-space: nowrap;
    overflow: hidden;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .single-goal-card {
    width: 100%;
  }
}

// mobile screen
@media (max-width: 480px) {
  .goal-cards-container {
    flex-direction: column;

    .single-goal-card {
      width: 100%;
      display: flex;
      cursor: pointer;
      flex-direction: column;
      flex: 100% !important;
      max-width: 100% !important;
      .goal-card-row1 {
        .goal-top {
          display: flex !important;

          .goal-date {
            margin-bottom: 2px;
          }
        }
        .goal-name {
          margin-left: -39px;
          font-size: 15px;
        }
      }
    }
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .goal-cards-container {
    flex-direction: row;
    flex-wrap: wrap;

    .single-goal-card {
      width: 100%;
      display: flex;
      cursor: pointer;
      flex-direction: column;
      flex: 100% !important;
      max-width: 100% !important;

      .portfolio-asset-content {
        padding: 0 10px;
      }
    }
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .goal-cards-container {
    flex-direction: row;
    flex-wrap: wrap;
    .single-goal-card {
      flex: 0 0 49% !important;
      max-width: 49% !important;
    }
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .goal-cards-container {
    flex-direction: row;
    flex-wrap: wrap;
    .single-goal-card {
      flex: 0 0 49% !important;
      max-width: 49% !important;
    }
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .goal-cards-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
